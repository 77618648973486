import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate} from 'react-router-dom'
import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useForm } from "react-hook-form"
import { toast } from 'react-toastify'
import { addCategoryAsync, resetCategoryAddStatus, selectCategoryAddStatus } from '../../categories/CategoriesSlice'

export const AddCategory = () => {

    const {register,handleSubmit,reset,formState: { errors }} = useForm()

    const dispatch=useDispatch()
    const categoryAddStatus=useSelector(selectCategoryAddStatus)
    const navigate=useNavigate()
    const theme=useTheme()
    const is1100=useMediaQuery(theme.breakpoints.down(1100))
    const is480=useMediaQuery(theme.breakpoints.down(480))

    useEffect(()=>{
        if(categoryAddStatus==='fullfilled'){
            reset()
            toast.success("Đã tạo danh mục mới")
            navigate("/admin/dashboard")
        }
        else if(categoryAddStatus==='rejected'){
            toast.error("Lỗi khi tạo danh mục, vui lòng thử lại sau")
        }
    },[categoryAddStatus])

    useEffect(()=>{
        return ()=>{
            dispatch(resetCategoryAddStatus())
        }
    },[])

    const handleAddCategory=(data)=>{
        const newCategory={
            name: data.name
        }
        console.log({newCategory, data})
        dispatch(addCategoryAsync(newCategory))
    }

    
  return (
    <Stack p={'0 16px'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'} >
        

        <Stack width={is1100?"100%":"60rem"} rowGap={4} mt={is480?4:6} mb={6} component={'form'} noValidate onSubmit={handleSubmit(handleAddCategory)}> 
            
            {/* feild area */}
            <Stack rowGap={3}>
                <Stack>
                    <Typography variant='h6' fontWeight={400} gutterBottom>Tên danh mục</Typography>
                    <TextField {...register("name",{required:'Tên danh mục bắt buộc'})}/>
                </Stack> 
            </Stack>

            {/* action area */}
            <Stack flexDirection={'row'} alignSelf={'flex-end'} columnGap={is480?1:2}>
                <Button size={is480?'medium':'large'} variant='contained' type='submit'>Thêm danh mục</Button>
                <Button size={is480?'medium':'large'} variant='outlined' color='error' component={Link} to={'/admin/dashboard'}>Cancel</Button>
            </Stack>

        </Stack>

    </Stack>
  )
}
