import React from 'react'
import { Navbar } from '../features/navigation/components/Navbar'
import { ExcelImport } from '../features/admin/components/ExcelImport'

export const ExcelImportPage = () => {
  return (
    <>
    <Navbar/>
    <ExcelImport/>
    </>
  )
}
