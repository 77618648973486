import { axiosi } from "../../config/axios"

//Thêm mới một danh mục
export const addCategory=async(data)=>{
    try {
        const res=await axiosi.post('/categories',data)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

//Thêm mới danh sách danh mục
export const addCategories=async(categories)=>{
    try {
        const res=await axiosi.post('/categories/import',categories)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

//Lấy tất cả danh mục
export const fetchAllCategories=async()=>{
    try {
        const res=await axiosi.get("/categories")
        return res.data
    } catch (error) {
        throw error.response.data
    }
}