import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  getRevenueAsync,
  getInventoryAsync,
} from "../features/order/OrderSlice";
import { formatDate } from "../utils/formatDate";
import { Navbar } from "../features/navigation/components/Navbar";

export const RevenueManagementPage = () => {
  const dispatch = useDispatch();
  const { inventory, revenueByDate, status } = useSelector(
    (state) => state.OrderSlice
  );
  const [chartData, setChartData] = useState({});
  const [selectedData, setSelectedData] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const [sortConfig, setSortConfig] = useState({
    key: "totalSold",
    direction: "desc",
  });

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Handle click on bar
  const handleBarClick = (event, elements) => {
    if (elements.length > 0) {
      const index = elements[0].index; // Get index of the clicked bar
      const datasetIndex = elements[0].datasetIndex; // Get dataset index (optional)
      const label = chartData.labels[index]; // Label for the clicked bar
      const value = chartData.datasets[datasetIndex].data[index]; // Value for the clicked bar
      console.log("Clicked bar data: ", label, value, revenueByDate);
      //compare two dates
      let getItemsByDate = revenueByDate.find((item) => item._id === label);

      // Store selected data for displaying details
      console.log("getItemsByDate", getItemsByDate);
      setSelectedData({ label, value, getItemsByDate });
    }
  };

  useEffect(() => {
    dispatch(getRevenueAsync());
    dispatch(getInventoryAsync());
  }, [dispatch]);

  useEffect(() => {
    if (
      status === "fulfilled" &&
      Array.isArray(revenueByDate) &&
      revenueByDate.length > 0
    ) {
      const labels = revenueByDate.map((item) => {
        return item._id;
      });
      const data = revenueByDate.map((item) => {
        return item.totalRevenue;
      });
      const dataObject = {
        labels: labels,
        datasets: [
          {
            label: "Tổng doanh thu",
            data: data,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            borderWidth: 1,
          },
        ],
      };
      setChartData(dataObject);
    }
  }, [status, revenueByDate]);

  const sortedInventory = useMemo(() => {
    if (!sortConfig.key) return inventory;
    return [...inventory].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [inventory, sortConfig]);

  const renderTableOfProducts = (selectedData) => {
    const items = selectedData?.getItemsByDate?.item || [];
    const mergedItems = items.length > 0 ? items.flat() : [];

    return (
      <div style={styles.detailsContainer}>
        {selectedData && (
          <div>
            <h2 style={styles.heading}>Chi tiết doanh thu</h2>
            <p style={styles.detail}>
              <strong>Ngày:</strong> {selectedData.label}
            </p>
            <p style={styles.detail}>
              <strong>Doanh thu:</strong> {selectedData.value.toLocaleString()}{" "}
              VNĐ
            </p>
            {mergedItems.length > 0 && (
              <p style={styles.detail}>
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={styles.th}>Tên sản phẩm</th>
                      <th style={styles.th}>Số lượng</th>
                      <th style={styles.th}>Giá nhập</th>
                      <th style={styles.th}>Giá bán</th>
                      <th style={styles.th}>Lời</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mergedItems.map((item) => (
                      <tr key={item.product._id}>
                        <td style={styles.td}>{item.product.title}</td>
                        <td style={styles.td}>{item.quantity}</td>
                        <td style={styles.td}>
                          {item.product.costPrice * item.quantity} VNĐ
                        </td>
                        <td style={styles.td}>
                          {item.product.price * item.quantity} VNĐ
                        </td>
                        <td style={styles.td}>
                          {(item.product.price - item.product.costPrice) *
                            item.quantity}{" "}
                          VNĐ
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr style={{ fontWeight: "bold" }}>
                      <td style={styles.td} colSpan="4">
                        Tổng lời:{" "}
                      </td>
                      <td style={styles.td}>
                        {mergedItems.reduce(
                          (acc, item) =>
                            acc +
                            (item.product.price - item.product.costPrice) *
                              item.quantity,
                          0
                        )}{" "}
                        VNĐ
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </p>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderTableOfInventory = (data, handleSort) => {
    return (
      <div>
        {inventory && inventory.length > 0 ? ( // Kiểm tra trạng thái để hiển thị
          <div style={styles.detailsContainer}>
            <h2
              style={{ ...styles.heading, cursor: "pointer" }} // Thêm hiệu ứng con trỏ
              onClick={() => setIsExpanded(!isExpanded)} // Toggle trạng thái khi nhấn vào heading
            >
              Tồn kho (click để mở rộng){isExpanded ? " -" : " +"}
              {/* Hiển thị mũi tên chỉ trạng thái */}
            </h2>
            {isExpanded && (
              <table style={styles.table}>
                <thead>
                  <tr>
                    <th style={styles.th} onClick={() => handleSort("name")}>
                      Sản phẩm{" "}
                      {sortConfig.key === "name"
                        ? sortConfig.direction === "asc"
                          ? "↑"
                          : "↓"
                        : ""}
                    </th>
                    <th
                      style={styles.th}
                      onClick={() => handleSort("inventory")}
                    >
                      Số lượng tồn{" "}
                      {sortConfig.key === "inventory"
                        ? sortConfig.direction === "asc"
                          ? "↑"
                          : "↓"
                        : ""}
                    </th>
                    <th
                      style={styles.th}
                      onClick={() => handleSort("totalSold")}
                    >
                      Đã bán{" "}
                      {sortConfig.key === "totalSold"
                        ? sortConfig.direction === "asc"
                          ? "↑"
                          : "↓"
                        : ""}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedInventory.map((item) => (
                    <tr key={item.productId}>
                      <td style={styles.td}>{item.name}</td>
                      <td style={styles.td}>{item.inventory}</td>
                      <td style={styles.td}>{item.totalSold}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        ) : (
          isExpanded && (
            <div style={styles.noData}>
              <h2>Kho hàng trống</h2>
            </div>
          )
        )}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      {chartData && Object.keys(chartData).length > 0 ? (
        <div style={styles.section}>
          <h2 style={styles.heading}>Doanh thu theo ngày</h2>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "auto",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              {" "}
              <Bar
                data={chartData}
                options={{
                  plugins: {
                    legend: {
                      position: "top", // Vị trí hiển thị chú thích
                    },
                  },
                  onClick: (event, elements) => handleBarClick(event, elements), // Sự kiện click
                  maintainAspectRatio: false, // Tắt duy trì tỷ lệ
                }}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              {" "}
              {renderTableOfProducts(selectedData)}
            </div>
          </div>
        </div>
      ) : (
        <div style={styles.noData}>
          <h2>Không có dữ liệu doanh thu</h2>
        </div>
      )}
      {renderTableOfInventory(sortedInventory, handleSort)}
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "5px",
  },
  section: {
    marginBottom: "20px",
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: "20px",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f2f2f2",
    textAlign: "left",
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "left",
  },
  noData: {
    textAlign: "center",
    color: "#888",
    fontStyle: "italic",
  },
  detailsContainer: {
    border: "1px solid #ddd",
    backgroundColor: "#f9f9f9",
  },
  detail: {
    marginBottom: "5px",
  },
};
