import React from 'react';
import { PayPalScriptProvider, PayPalButtons, FUNDING } from '@paypal/react-paypal-js';
import './Paypal.css';

const Paypal = ({totalAmount, handleUpdatePaymentStatus}) => {
    console.log({totalAmount});
    return (
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
            <div className="Paypal">
                <PayPalButtons
                    style={{ layout: "vertical", width: "100%" }} // Full width
                    fundingSource={FUNDING.PAYPAL}
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: totalAmount, // payment amount
                                    },
                                },
                            ],
                        });
                    }}
                    onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                            alert(`Transaction completed by ${details.payer.name.given_name}`);
                            handleUpdatePaymentStatus({paymentStatus: 'PAID'});
                        });
                    }}
                />
            </div>
        </PayPalScriptProvider>
    );
}

export default Paypal;
