import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  clearSelectedProduct,
  fetchProductByIdAsync,
  resetProductUpdateStatus,
  selectProductUpdateStatus,
  selectSelectedProduct,
  updateProductByIdAsync,
} from "../../products/ProductSlice";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { selectBrands } from "../../brands/BrandSlice";
import { selectCategories } from "../../categories/CategoriesSlice";
import { toast } from "react-toastify";

export const ProductUpdate = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(selectSelectedProduct);
  const brands = useSelector(selectBrands);
  const categories = useSelector(selectCategories);
  const productUpdateStatus = useSelector(selectProductUpdateStatus);
  const navigate = useNavigate();
  const theme = useTheme();
  const is1100 = useMediaQuery(theme.breakpoints.down(1100));
  const is480 = useMediaQuery(theme.breakpoints.down(480));

  useEffect(() => {
    if (id) {
      dispatch(fetchProductByIdAsync(id));
    }
  }, [id]);

  useEffect(() => {
    if (productUpdateStatus === "fullfilled") {
      toast.success("Sản phẩm đã được cập nhật");
      navigate("/admin/dashboard");
    } else if (productUpdateStatus === "rejected") {
      toast.error("Có lỗi khi cập nhật sản phẩm, vui lòng thử lại sau");
    }
  }, [productUpdateStatus]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectedProduct());
      dispatch(resetProductUpdateStatus());
    };
  }, []);

  const handleProductUpdate = (data) => {
    const productUpdate = {
      ...data,
      _id: selectedProduct._id,
      images: [data?.image0, data?.image1, data?.image2, data?.image3],
    };
    delete productUpdate?.image0;
    delete productUpdate?.image1;
    delete productUpdate?.image2;
    delete productUpdate?.image3;

    dispatch(updateProductByIdAsync(productUpdate));
  };

  return (
    <Stack
      p={"0 16px"}
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"row"}
    >
      {selectedProduct && (
        <Stack
          width={is1100 ? "100%" : "60rem"}
          rowGap={4}
          mt={is480 ? 4 : 6}
          mb={6}
          component={"form"}
          noValidate
          onSubmit={handleSubmit(handleProductUpdate)}
        >
          {/* feild area */}
          <Stack rowGap={3}>
            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Tên sản phẩm
              </Typography>
              <TextField
                {...register("title", {
                  required: "Sản phẩm là bắt buộc",
                  value: selectedProduct.title,
                })}
              />
            </Stack>

            <Stack flexDirection={"row"}>
              <FormControl fullWidth>
                <InputLabel id="brand-selection">Nhãn hiệu</InputLabel>
                <Select
                  defaultValue={selectedProduct.brand || ""}
                  {...register("brand", { required: "Nhãn hiệu là bắt buộc" })}
                  labelId="brand-selection"
                  label="Brand"
                >
                  {brands.map((brand) => (
                    <MenuItem value={brand._id}>{brand.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="category-selection">Danh mục</InputLabel>
                <Select
                  defaultValue={selectedProduct.category}
                  {...register("category", {
                    required: "Danh mục là bắt buộc",
                  })}
                  labelId="category-selection"
                  label="Category"
                >
                  {categories.map((category) => (
                    <MenuItem value={category._id}>{category.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Mô tả
              </Typography>
              <TextField
                multiline
                rows={4}
                {...register("description", {
                  required: "Mô tả là bắt buộc",
                  value: selectedProduct.description,
                })}
              />
            </Stack>

            <Stack flexDirection={"row"}>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Giá nhập
                </Typography>
                <TextField
                  type="number"
                  {...register("costPrice", {
                    required: "Giá nhập là bắt buộc",
                    value: selectedProduct.costPrice,
                  })}
                />
              </Stack>
            </Stack>
            <Stack flexDirection={"row"}>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Giá bán
                </Typography>
                <TextField
                  type="number"
                  {...register("price", {
                    required: "Giá bán là bắt buộc",
                    value: selectedProduct.price,
                  })}
                />
              </Stack>
              <Stack flex={1}>
                <Typography variant="h6" fontWeight={400} gutterBottom>
                  Giảm giá {is480 ? "%" : "Phần trăm"}
                </Typography>
                <TextField
                  type="number"
                  {...register("discountPercentage", {
                    required: "Giảm giá là bắt buộc",
                    value: selectedProduct.discountPercentage,
                  })}
                />
              </Stack>
            </Stack>

            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Tồn kho
              </Typography>
              <TextField
                type="number"
                {...register("stockQuantity", {
                  required: "Tồn kho là bắt buộc",
                  value: selectedProduct.stockQuantity,
                })}
              />
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Ảnh đại diện
              </Typography>
              <TextField
                {...register("thumbnail", {
                  required: "Ảnh đại diện là bắt buộc",
                  value: selectedProduct.thumbnail,
                })}
              />
            </Stack>

            <Stack>
              <Typography variant="h6" fontWeight={400} gutterBottom>
                Ảnh sản phẩm
              </Typography>

              <Stack rowGap={2}>
                {selectedProduct.images.map((image, index) => (
                  <TextField
                    {...register(`image${index}`, {
                      required: "Ảnh là bắt buộc",
                      value: image,
                    })}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>

          {/* action area */}
          <Stack
            flexDirection={"row"}
            alignSelf={"flex-end"}
            columnGap={is480 ? 1 : 2}
          >
            <Button
              size={is480 ? "medium" : "large"}
              variant="contained"
              type="submit"
            >
                Cập nhật sản phẩm
            </Button>
            <Button
              size={is480 ? "medium" : "large"}
              variant="outlined"
              color="error"
              component={Link}
              to={"/admin/dashboard"}
            >
              Hủy
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
