import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
// import { productsCreate } from "../features/product/productSlice";
import { useDispatch } from "react-redux";
import { addProductsAsync } from "../../products/ProductSlice";
import { addCategoriesAsync } from "../../categories/CategoriesSlice";
import { addBrandsAsync } from "../../brands/BrandSlice";

export const ExcelImport = () => {
  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useState("");
  const [tableData, setTableData] = useState([]);

  // Handle file upload and parse Excel data
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      setTableData(jsonData);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleChange = (e) => {
    setSelectedType(e.target.value);
    setTableData([]);
  };

  const handleAddData = () => {
    switch (selectedType) {
      case "product":
        const products = tableData.slice(1).map((row) => ({
          title: row[0],
          description: row[1],
          price: row[2],
          discountPercentage: row[3],
          category: row[4],
          brand: row[5],
          stockQuantity: row[6],
          thumbnail: row[7],
          images: row[8].split(","),
          costPrice: row[9],
          isDeleted: false,
        }));
        console.log({ tableData, products });
        dispatch(addProductsAsync(products));
        setTableData([]);
        break;

      case "category":
        const categories = tableData.slice(1).map((row) => ({
          name: row[0],
        }));
        console.log({ tableData, categories });
        dispatch(addCategoriesAsync(categories));
        setTableData([]);
        break;

      case "brand":
        const brands = tableData.slice(1).map((row) => ({
          name: row[0],
        }));
        console.log({ tableData, brands });
        dispatch(addBrandsAsync(brands));
        setTableData([]);
        break;

      default:
        break;
    }
    return;
  };

  useEffect(() => {
    console.log({ tableData });
  }, [tableData]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".xlsx, .xls, .csv, .numbers",
  });

  return (
    <div style={styles.container}>
      <div style={styles.topRow}>
        <select
          style={styles.select}
          onChange={handleChange}
          value={selectedType}
        >
          <option value="">--Select type--</option>
          <option value="product">Product</option>
          <option value="category">Category</option>
          <option value="brand">Brand</option>
        </select>

        {selectedType && (
          <div {...getRootProps()} style={styles.dropzone}>
            <input {...getInputProps()} />
            <p>Kéo thả file Excel vào đây hoặc click để chọn file</p>
          </div>
        )}
      </div>

      {tableData.length > 0 && (
        <>
          <table style={styles.table}>
            <thead>
              <tr>
                {tableData[0].map((header, index) => (
                  <th key={index} style={styles.th}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.slice(1).map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={cellIndex} style={styles.td}>
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <button
              onClick={() => handleAddData()}
              style={{
                backgroundColor: "#4CAF50", // Green color
                color: "white",
                padding: "10px 20px",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "16px",
                fontWeight: "bold",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#45a049")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#4CAF50")}
            >
              Thêm
            </button>
          </div>
        </>
      )}
    </div>
  );
};

// CSS-in-JS styles for basic styling
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  topRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dropzone: {
    display: "flex",
    border: "2px dashed #888",
    textAlign: "center",
    cursor: "pointer",
    height: "45px",
    justifyContent: "center",
    alignItems: "center",
    width: "30%",
    marginLeft: "10px",
  },
  table: {
    width: "100%",
    marginTop: "10px",
    borderCollapse: "collapse",
    tableLayout: "fixed",
  },
  th: {
    border: "1px solid #ddd",
    padding: "8px",
    backgroundColor: "#f4f4f4",
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "150px", // Adjust width for header cells if necessary
  },
  td: {
    border: "1px solid #ddd",
    padding: "8px",
    textAlign: "center",
    width: "100%",
    whiteSpace: "nowrap", // Prevents text from wrapping to a new line
    overflow: "hidden", // Ensures content doesn't overflow the cell
    textOverflow: "ellipsis", // Adds "..." for overflowed text
    maxWidth: "150px", // Sets a max width for the cell (adjust as needed)
  },
  select: {
    flex: 1,
    height: "45px",
  },
};

export default ExcelImport;
