import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllCategories } from "./CategoriesApi";
import { addCategory, addCategories } from "./CategoriesApi";
const initialState = {
  status: "idle",
  categoryAddStatus: "idle",
  categories: [],
  errors: null,
};

export const fetchAllCategoriesAsync = createAsyncThunk(
  "categories/fetchAllCategoriesAsync",
  async () => {
    const categories = await fetchAllCategories();
    return categories;
  }
);

export const addCategoryAsync=createAsyncThunk("categories/addCategoryAsync",async(data)=>{
    const addedCategory=await addCategory(data)
    return addedCategory
})

export const addCategoriesAsync=createAsyncThunk("categories/addCategoriesAsync",async(categories)=>{
    const addedCategories=await addCategories(categories)
    return addedCategories
})

const categorySlice = createSlice({
  name: "categorySlice",
  initialState: initialState,
  reducers: {
    resetCategoryAddStatus: (state) => {
      state.categoryAddStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCategoryAsync.pending, (state) => {
        state.categoryAddStatus = "pending";
      })
      .addCase(addCategoryAsync.fulfilled, (state, action) => {
        state.categoryAddStatus = "fullfilled";
        state.categories.push(action.payload);
      })
      .addCase(addCategoryAsync.rejected, (state, action) => {
        state.categoryAddStatus = "rejected";
        state.errors = action.error;
      })
      .addCase(fetchAllCategoriesAsync.pending, (state) => {
        state.status = "idle";
      })
      .addCase(fetchAllCategoriesAsync.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.categories = action.payload;
      })
      .addCase(fetchAllCategoriesAsync.rejected, (state, action) => {
        state.status = "rejected";
        state.errors = action.error;
      })
      .addCase(addCategoriesAsync.pending, (state) => {
        state.categoryAddStatus = "pending";
      })
      .addCase(addCategoriesAsync.fulfilled, (state, action) => {
        state.categoryAddStatus = "fulfilled";
        state.categories = action.payload;
      })
      .addCase(addCategoriesAsync.rejected, (state, action) => {
        state.categoryAddStatus = "rejected";
        state.errors = action.error;
      });
  },
});

// exporting selectors
export const selectCategoryStatus = (state) => state.CategoriesSlice.status;
export const selectCategories = (state) => state.CategoriesSlice.categories;
export const selectCategoryErrors = (state) => state.CategoriesSlice.errors;

export const selectCategoryAddStatus=(state)=>state.CategoriesSlice.categoryAddStatus

// exporting actions
export const { resetCategoryAddStatus } = categorySlice.actions;
export default categorySlice.reducer;
