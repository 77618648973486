import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link, useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Chip,
  Stack,
  useMediaQuery,
  useTheme,
  TextField,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "../../user/UserSlice";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { selectCartItems } from "../../cart/CartSlice";
import { selectLoggedInUser } from "../../auth/AuthSlice";
import { selectWishlistItems } from "../../wishlist/WishlistSlice";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import TuneIcon from "@mui/icons-material/Tune";
import SearchIcon from "@mui/icons-material/Search";
import {
  fetchProductsByKeywordAsync,
  selectProductIsFilterOpen,
  toggleFilters,
} from "../../products/ProductSlice";

export const Navbar = ({ isProductList = false }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [searchQuery, setSearchQuery] = React.useState("");
  const userInfo = useSelector(selectUserInfo);
  const cartItems = useSelector(selectCartItems);
  const loggedInUser = useSelector(selectLoggedInUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const is480 = useMediaQuery(theme.breakpoints.down(480));

  const wishlistItems = useSelector(selectWishlistItems);
  const isProductFilterOpen = useSelector(selectProductIsFilterOpen);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleToggleFilters = () => {
    dispatch(toggleFilters());
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchQuery.trim()) {
      dispatch(fetchProductsByKeywordAsync(searchQuery));
    }
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: "white",
        boxShadow: "none",
        color: "text.primary",
      }}
    >
      <Toolbar
        sx={{
          p: 1,
          height: "4rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          {loggedInUser?.isAdmin ? "AhaGear-Admin" : "AhaGear"}
        </Typography>

        <TextField
          variant="outlined"
          size="small"
          placeholder="Tìm kiếm..."
          value={searchQuery}
          onChange={handleSearchChange}
          onKeyPress={(e) => e.key === "Enter" && handleSearchSubmit()}
          sx={{ width: { xs: "100%", md: "30%" } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleSearchSubmit}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          columnGap={2}
        >
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {loggedInUser?.isVerified && (
              <>
                <MenuItem key="home" onClick={handleCloseUserMenu}>
                  <Typography
                    component={Link}
                    color={"text.primary"}
                    sx={{ textDecoration: "none" }}
                    to="/"
                    textAlign="center"
                  >
                    Trang chủ
                  </Typography>
                </MenuItem>
                {/* <MenuItem key="profile" onClick={handleCloseUserMenu}>
                  <Typography
                    component={Link}
                    color={"text.primary"}
                    sx={{ textDecoration: "none" }}
                    to="/profile"
                    textAlign="center"
                  >
                    Thông tin cá nhân
                  </Typography>
                </MenuItem> */}
              </>
            )}

            {loggedInUser?.isAdmin ? (
              <>
                <MenuItem key="excel-import" onClick={handleCloseUserMenu}>
                  <Typography
                    component={Link}
                    color={"text.primary"}
                    sx={{ textDecoration: "none" }}
                    to="/admin/excel-import"
                    textAlign="center"
                  >
                    Import excel
                  </Typography>
                </MenuItem>
                <MenuItem key="excel-import" onClick={handleCloseUserMenu}>
                  <Typography
                    component={Link}
                    color={"text.primary"}
                    sx={{ textDecoration: "none" }}
                    to="/admin/revenue-management"
                    textAlign="center"
                  >
                    Quản lý doanh thu
                  </Typography>
                </MenuItem>
                <MenuItem key="add-product" onClick={handleCloseUserMenu}>
                  <Typography
                    component={Link}
                    color={"text.primary"}
                    sx={{ textDecoration: "none" }}
                    to="/admin/add-product"
                    textAlign="center"
                  >
                    Thêm sản phẩm
                  </Typography>
                </MenuItem>
                <MenuItem key="add-category" onClick={handleCloseUserMenu}>
                  <Typography
                    component={Link}
                    color={"text.primary"}
                    sx={{ textDecoration: "none" }}
                    to="/admin/add-category"
                    textAlign="center"
                  >
                    Thêm danh mục sản phẩm
                  </Typography>
                </MenuItem>
                <MenuItem key="orders" onClick={handleCloseUserMenu}>
                  <Typography
                    component={Link}
                    color={"text.primary"}
                    sx={{ textDecoration: "none" }}
                    to="/admin/orders"
                    textAlign="center"
                  >
                    Danh sách đơn
                  </Typography>
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem key="my-orders" onClick={handleCloseUserMenu}>
                  <Typography
                    component={Link}
                    color={"text.primary"}
                    sx={{ textDecoration: "none" }}
                    to="/orders"
                    textAlign="center"
                  >
                    Đơn hàng của tôi
                  </Typography>
                </MenuItem>
              </>
            )}
            {loggedInUser?.isVerified && (
              <MenuItem key="logout" onClick={handleCloseUserMenu}>
                <Typography
                  component={Link}
                  color={"text.primary"}
                  sx={{ textDecoration: "none" }}
                  to="/logout"
                  textAlign="center"
                >
                  Logout
                </Typography>
              </MenuItem>
            )}
          </Menu>
          {loggedInUser.isAdmin && <Button variant="contained">Admin</Button>}
          <Stack
            sx={{
              flexDirection: "row",
              columnGap: "1rem",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {cartItems?.length > 0 && (
              <Badge badgeContent={cartItems.length} color="error">
                <IconButton onClick={() => navigate("/cart")}>
                  <ShoppingCartOutlinedIcon />
                </IconButton>
              </Badge>
            )}

            {!loggedInUser?.isAdmin && (
              <Stack>
                <Badge badgeContent={wishlistItems?.length} color="error">
                  <IconButton component={Link} to={"/wishlist"}>
                    <FavoriteBorderIcon />
                  </IconButton>
                </Badge>
              </Stack>
            )}
            {isProductList && (
              <IconButton onClick={handleToggleFilters}>
                <TuneIcon sx={{ color: isProductFilterOpen ? "black" : "" }} />
              </IconButton>
            )}
          </Stack>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={userInfo?.name} src="null" />
            </IconButton>
          </Tooltip>
          <Typography variant="h6" fontWeight={300}>
            {is480
              ? `${userInfo?.name.toString().split(" ")[0]}`
              : `Hey👋, ${userInfo?.name}`}
          </Typography>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
