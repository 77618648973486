import React from 'react'
import { Navbar } from '../features/navigation/components/Navbar'
import { AddCategory } from '../features/admin/components/AddCategory'

export const AddCategoryPage = () => {
  return (
    <>
    <Navbar/>
    <AddCategory/>
    </>
  )
}
