import { axiosi } from "../../config/axios";

//Thêm mới một sản phẩm
export const addProduct=async(data)=>{
    try {
        const res=await axiosi.post('/products',data)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

//Thêm mới danh sách sản phẩm
export const addProducts=async(products)=>{
    try {
        const res=await axiosi.post('/products/import',products)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

//Lấy tất cả sản phẩm
export const fetchProducts=async(filters)=>{

    let queryString=''

    if(filters.brand){
        filters.brand.map((brand)=>{
            queryString+=`brand=${brand}&`
        })
    }
    if(filters.category){
        filters.category.map((category)=>{
            queryString+=`category=${category}&`
        })
    }

    if(filters.pagination){
        queryString+=`page=${filters.pagination.page}&limit=${filters.pagination.limit}&`
    }

    if(filters.sort){
        queryString+=`sort=${filters.sort.sort}&order=${filters.sort.order}&`
    }

    if(filters.user){
        queryString+=`user=${filters.user}&`
    }
    
    try {
        const res=await axiosi.get(`/products?${queryString}`)
        const totalResults=await res.headers.get("X-Total-Count")
        return {data:res.data,totalResults:totalResults}
    } catch (error) {
        throw error.response.data
    }
}

//Lấy sản phẩm theo id
export const fetchProductById=async(id)=>{
    try {
        const res=await axiosi.get(`/products/${id}`)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

//Cập nhật sản phẩm theo id
export const updateProductById=async(update)=>{
    try {
        const res=await axiosi.patch(`/products/${update._id}`,update)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

//Khôi phục sản phẩm theo id
export const undeleteProductById=async(id)=>{
    try {
        const res=await axiosi.patch(`/products/undelete/${id}`)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

//Xóa sản phẩm theo id
export const deleteProductById=async(id)=>{
    try {
        const res=await axiosi.delete(`/products/${id}`)
        return res.data
    } catch (error) {
        throw error.response.data
    }
}

//Lấy sản phẩm theo keyword
export const fetchProductsByKeyword = async (keyword) => {
    try {
        const res = await axiosi.post('/products/search', { keyword });
        return res.data;
    } catch (error) {
        const errorMessage = error.response?.data?.message || "An unexpected error occurred";
        throw new Error(errorMessage);
    }
};

//Lấy tồn kho
export const fetchInventory = async () => {
    try {
        const res = await axiosi.get('/products/inventory');
        return res.data;
    } catch (error) {
        const errorMessage = error.response?.data?.message || "An unexpected error occurred";
        console.error("Error fetching inventory:", {error});
        throw new Error(errorMessage);
    }
}